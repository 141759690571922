<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="openModal"
        >
          <IconCloudDownload></IconCloudDownload>
        </div>
      </template>
      <span>Télecharger Facture</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :scrollable="typeModal == 'zip' ? false : true"
      :persistent="true"
      :hide-overlay="true"
      content-class="custom-vuetify-dialog-export-excel"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Générer un {{ typeModal }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>

        <v-card-text class="body-card export-excel-modal mt-2">
          <v-form class="mt-2">
            <v-row :class="{ 'select-export-type': typeModal == 'xls' }">
              <v-col
                cols
                :xs="typeModal == 'xls' ? 6 : 12"
                :sm="typeModal == 'xls' ? 6 : 12"
                :md="typeModal == 'xls' ? 6 : 12"
              >
                <v-select
                  :class="{ 'mt-2': typeModal == 'zip' }"
                  :items="ListType"
                  v-model="typeModal"
                  label="Type de fichier"
                  @change="handleZip"
                  dense
                  outlined
                  :rules="requiredRule"
                  required
                  class="msg-error"
                  validate-on-blur
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col cols xs="6" sm="6" md="6" v-if="typeModal == 'xls'">
                <v-select
                  :items="computedModel"
                  v-model="modelSelected"
                  @change="handleUpdateModel"
                  label="Modèle"
                  dense
                  outlined
                  :rules="requiredRule"
                  class="msg-error"
                  validate-on-blur
                  required
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                modelToUpdate &&
                  modelToUpdate.columns &&
                  modelToUpdate.columns.length > 0 &&
                  typeModal == 'xls'
              "
              class="checked-tous-file"
            >
              <v-col>
                <v-checkbox
                  v-model="checkAll"
                  @change="checkAllColumnUpdate"
                  color="#704ad1"
                  hide-details
                  label="Tous"
                  :key="checkAll"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              v-if="
                modelToUpdate &&
                  modelToUpdate.columns &&
                  modelToUpdate.columns.length > 0 &&
                  typeModal == 'xls'
              "
            >
              <div class="draggable-modal">
                <draggable :list="modelToUpdate.columns">
                  <v-row
                    class="row"
                    v-for="(column, index) in modelToUpdate.columns"
                    :key="index"
                  >
                    <v-col cols xs="2" sm="2" md="2" class="move-export">
                      <div>
                        <v-icon color="#704ad1" class="pointer"
                          >mdi-cursor-move</v-icon
                        >
                      </div>
                    </v-col>
                    <v-col
                      cols
                      xs="2"
                      sm="2"
                      md="2"
                      class="margin-row-1-export"
                    >
                      <v-checkbox
                        :id="'checkbox-1' + index"
                        name="checkbox-1"
                        v-model="column.checked"
                        @change="computedModelUpdateCheck"
                        color="#704ad1"
                        hide-details
                      ></v-checkbox>
                    </v-col>

                    <v-col
                      class="margin-row-2-export"
                      cols
                      xs="8"
                      sm="8"
                      md="8"
                    >
                      <v-text-field
                        :label="column.description"
                        :persistent-placeholder="true"
                        :placeholder="column.description"
                        :disabled="
                          column.column == '$num_facture' &&
                            oldModalToUpdate.is_system
                        "
                        required
                        v-model="column.column_value"
                        autocomplete="off"
                        outlined
                        :rules="requiredRule"
                        class="msg-error"
                        validate-on-blur
                        color="#704ad1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols xs="1" sm="1" md="1">
                      <v-icon
                        class="pointer"
                        color="success"
                        v-if="
                          column.column == '$num_dossier' ||
                            column.column == '$client_dossier' ||
                            column.column == '$montant_final' ||
                            column.column == '$bureau_etude' ||
                            column.column == '$installeur' ||
                            column.column == '$date_paiement_dossier' ||
                            column.column == '$date_depot'
                        "
                        title="Cette colonne pour les factures support / Master"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="primary"
                        v-if="
                          column.column == '$qte' ||
                            column.column == '$pu_ht' ||
                            column.column == '$ht_produit' ||
                            column.column == '$nom_produit' ||
                            column.column == '$description_produit' ||
                            column.column == '$ref_produit'
                        "
                        title="Cette colonne peut faire la duplication des factures"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="yellow"
                        v-if="column.column == '$comptabilite'"
                        title="Cette colonne pour l'interface comptabilité"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="secondary"
                        v-if="column.column == '$sous_objet'"
                        title="Cette colonne pour les factures Sci / Mensuel"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="orange"
                        v-if="
                          column.column == '$organisme' ||
                            column.column == '$Kwh' ||
                            column.column == '$num_fac_avoir' ||
                            column.column == '$kwh_avoir' ||
                            column.column == '$ttc_avoir' ||
                            column.column == '$num_depot' ||
                            column.column == '$nom_depot' ||
                            column.column == '$ratio' ||
                            column.column == '$type' ||
                            column.code == '$master_filiale'
                        "
                        title="Cette colonne pour les factures obligées"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </v-col>
                  </v-row>
                </draggable>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleExportFile"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Générer
          </v-btn>
          <v-btn text @click="closeModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filter: { required: true },
    selected: { required: true },
    exportXls: { required: true },
    exportZip: { required: false },
    famille: { required: true },
    zipRequired: { required: false },
    xlsRequired: { required: false },
    hiddenContent: { required: false }
  },
  data() {
    return {
      requiredRule: [v => !!v || 'Cette sélection est requise.'],
      dialog: false,
      checkAll: false,
      typeModal: 'xls',
      modelSelected: null,
      loading: false,
      error: [],
      modelToUpdate: null,
      oldModelToUpdate: null,
      year: null
    };
  },
  methods: {
    ...mapActions(['getAllModelExports', 'updateModelExport']),
    openModal() {
      this.dialog = true;
      this.typeModal = 'xls';
    },
    closeModal() {
      this.dialog = false;
      this.vendeurs = [];
      this.year = null;
      this.checkAll = false;
      this.modelSelected = null;
      this.loading = false;
      this.error = [];
      this.modelSelected = this.computedModel[0]?.id;
      this.modelToUpdate = null;
      this.models?.map(item => {
        if (item.id == this.modelSelected) {
          if (
            item &&
            item.user_name == this.getUserData.name &&
            this.getUserData.role == 'admin'
          ) {
            this.modelToUpdate = { ...item };
            this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
            this.oldModelToUpdate = item;
            let selected = [];
            this.modelToUpdate.columns.forEach(item => {
              if (item.checked == true) {
                selected.push(item);
              }
            });
            if (this.modelToUpdate.columns.length == selected.length)
              this.checkAll = true;
          } else {
            this.modelToUpdate = null;
            this.oldModelToUpdate = null;
            this.oldModalToUpdate = null;
          }
        }
      });
    },
    computedModelUpdateCheck(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        this.modelToUpdate.columns.forEach(item => {
          if (item.checked == true) {
            selected.push(item);
          }
        });
        if (this.modelToUpdate.columns.length == selected.length)
          this.checkAll = true;
      }
    },
    checkAllColumnUpdate() {
      let tableCheck = [];
      if (this.checkAll == true) {
        this.modelToUpdate.columns.forEach(item => {
          item.checked = true;
        });
      } else {
        this.modelToUpdate.columns.forEach(item => {
          this.oldModalToUpdate.columns.forEach(column => {
            if (column.id === item.id) {
              item.checked = column.checked;
              tableCheck.push(column);
            }
          });
        });
      }

      if (tableCheck.length === this.modelToUpdate.columns.length) {
        this.checkAll = true;
        this.$forceUpdate();
      }
    },

    handleZip() {
      this.error = null;
      if (this.typeModal == 'zip') {
        this.modelToUpdate = null;
        this.oldModelToUpdate = null;
      } else {
        this.models?.map(item => {
          if (item.id == this.modelSelected) {
            if (
              item &&
              item.user_name == this.getUserData.name &&
              this.getUserData.role == 'admin'
            ) {
              this.modelToUpdate = { ...item };
              this.oldModelToUpdate = item;
            } else {
              this.modelToUpdate = null;
              this.oldModelToUpdate = null;
            }
          }
        });
      }
    },
    async handleExportFile() {
      let mois = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'aout',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
      ];
      this.error = [];
      this.loading = true;
      let selectColumns = [];
      if (this.modelToUpdate != null) {
        this.modelToUpdate?.columns?.forEach(element => {
          if (element.checked == true) {
            selectColumns.push(element);
          }
        });
        if (selectColumns.length == 0) {
          this.error = 'Séléctionner au moins un champ';
          this.loading = false;
        } else {
          let bodyForm = new FormData();
          bodyForm.append('id', this.modelToUpdate.id);
          bodyForm.append('name', this.modelToUpdate.name);
          if (this.modelToUpdate.statut == true) {
            this.modelToUpdate.statut = 1;
          } else {
            this.modelToUpdate.statut = 0;
          }
          bodyForm.append('statut', this.modelToUpdate.statut);
          for (let i = 0; i < this.modelToUpdate.columns.length; i++) {
            bodyForm.append(
              'columns[' + i + '][id]',
              this.modelToUpdate.columns[i].id
            );

            bodyForm.append(
              'columns[' + i + '][column]',
              this.modelToUpdate.columns[i].column
            );
            bodyForm.append(
              'columns[' + i + '][description]',
              this.modelToUpdate.columns[i].description
            );
            bodyForm.append(
              'columns[' + i + '][column_value]',
              this.modelToUpdate.columns[i].column_value
            );
            bodyForm.append('columns[' + i + '][index]', i);
            if (
              this.modelToUpdate.columns[i] &&
              this.modelToUpdate.columns[i].checked
            ) {
              bodyForm.append('columns[' + i + '][blocked]', 0);
            } else {
              bodyForm.append('columns[' + i + '][blocked]', 1);
            }
          }
          let payload = {
            oldModelToUpdate: this.oldModelToUpdate,
            newModel: bodyForm
          };
          const response = await this.updateModelExport(payload);
          if (response) {
            let bodyFormData = new FormData();
            Object.keys(this.filter).forEach(key => {
              if (key == 'col') {
                bodyFormData.append('column', this.filter[key]);
              }
            });
            if (this.famille != null) {
              bodyFormData.append('famille', this.famille);
            }
            bodyFormData.append('order', 'DESC');
            bodyFormData.append('modelExport_id', this.modelSelected);
            if (this.selected.length != 0) {
              for (let i = 0; i < this.selected.length; i++) {
                bodyFormData.append('ids[' + i + ']', this.selected[i]);
              }
            } else {
              Object.keys(this.filter).forEach(key => {
                if (key == 'date_fin' && this.filter[key] != null) {
                  bodyFormData.append('date_fin', this.filter[key]);
                } else if (key == 'comptabilite' && this.filter[key] != null) {
                  bodyFormData.append('comptabilite', this.filter[key]);
                } else if (key == 'year' && this.filter[key] != null) {
                  bodyFormData.append('year', this.filter[key]);
                  this.year = this.filter[key];
                } else if (key == 'month') {
                  if (this.filter[key] != null) {
                    bodyFormData.append('month', this.filter[key]);
                    let index = mois.indexOf(this.filter[key]);
                    let date_debut = moment(
                      this.year + '-' + (index + 1) + '-01'
                    )
                      .startOf('month')
                      .format('YYYY-MM-DD');
                    let date_fin = moment(this.year + '-' + (index + 1) + '-01')
                      .endOf('month')
                      .format('YYYY-MM-DD');
                    bodyFormData.append('date_fin', date_fin);
                    bodyFormData.append('date_debut', date_debut);
                  } else {
                    let date_debut = moment('' + this.year + '')
                      .startOf('year')
                      .format('YYYY-MM-DD');
                    let date_fin = moment('' + this.year + '')
                      .endOf('year')
                      .format('YYYY-MM-DD');
                    bodyFormData.append('date_fin', date_fin);
                    bodyFormData.append('date_debut', date_debut);
                  }
                } else if (key == 'date_debut' && this.filter[key] != null) {
                  bodyFormData.append('date_debut', this.filter[key]);
                } else if (key == 'filterFamilly' && this.filter[key] != null) {
                  bodyFormData.append('support_familly', this.filter[key].id);
                } else if (key == 'search' && this.filter[key] != null) {
                  bodyFormData.append('search', this.filter[key]);
                } else if (key == 'num' && this.filter[key] != null) {
                  bodyFormData.append('num', this.filter[key]);
                } else if (key == 'objet' && this.filter[key] != null) {
                  bodyFormData.append('objet', this.filter[key]);
                } else if (Array.isArray(this.filter[key])) {
                  if (key == 'filterMasterFiliale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'masters[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterSupport') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'supports[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterFiliale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'clients[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterStatut') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statut[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'filterStatutInstallateur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statue_installateur[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'filiale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'filiale[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'depot') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'depot[' + i + ']',
                        this.filter[key][i]
                      );
                    }
                  } else if (key == 'statue') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statue[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'master_filiale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'master_filiale[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'type') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'type[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'etat') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'etat[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'statut_client') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statut_client[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'vendeur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'vendeur_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'categorie') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'categorie_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'acheteur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'acheteur_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'mode_reglement') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'mode_reglement[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'vendeur_id') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'vendeur_id[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'acheteur_id') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'acheteur_id[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'family') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'family[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  }
                } else {
                  if (this.filter[key] != null) {
                    bodyFormData.append(key, this.filter[key]);
                  }
                }
              });
            }
            const response = await this.exportXls(bodyFormData);
            if (response.succes) {
              this.loading = false;
              this.closeModal();
              this.$emit('end', false);
              this.$alert('', response.msg, 'success');
            } else {
              this.loading = false;
              this.error = 'Une erreur est survenue';
            }
          }
        }
      } else {
        if (this.typeModal == 'xls') {
          let bodyFormData = new FormData();
          Object.keys(this.filter).forEach(key => {
            if (key == 'col') {
              bodyFormData.append('column', this.filter[key]);
            }
          });
          bodyFormData.append('famille', this.famille);
          bodyFormData.append('order', 'DESC');
          bodyFormData.append('modelExport_id', this.modelSelected);
          if (this.selected.length != 0) {
            for (let i = 0; i < this.selected.length; i++) {
              bodyFormData.append('ids[' + i + ']', this.selected[i]);
            }
          } else {
            Object.keys(this.filter).forEach(key => {
              if (key == 'date_fin' && this.filter[key] != null) {
                bodyFormData.append('date_fin', this.filter[key]);
              } else if (key == 'comptabilite' && this.filter[key] != null) {
                bodyFormData.append('comptabilite', this.filter[key]);
              } else if (key == 'year' && this.filter[key] != null) {
                bodyFormData.append('year', this.filter[key]);
              } else if (key == 'month') {
                if (this.filter[key] != null) {
                  bodyFormData.append('month', this.filter[key]);
                  let index = mois.indexOf(this.filter[key]);
                  let date_debut = moment(this.year + '-' + (index + 1) + '-01')
                    .startOf('month')
                    .format('YYYY-MM-DD');
                  let date_fin = moment(this.year + '-' + (index + 1) + '-01')
                    .endOf('month')
                    .format('YYYY-MM-DD');
                  bodyFormData.append('date_fin', date_fin);
                  bodyFormData.append('date_debut', date_debut);
                } else {
                  let date_debut = moment('' + this.year + '')
                    .startOf('year')
                    .format('YYYY-MM-DD');
                  let date_fin = moment('' + this.year + '')
                    .endOf('year')
                    .format('YYYY-MM-DD');
                  bodyFormData.append('date_fin', date_fin);
                  bodyFormData.append('date_debut', date_debut);
                }
              } else if (key == 'date_debut' && this.filter[key] != null) {
                bodyFormData.append('date_debut', this.filter[key]);
              } else if (key == 'filterFamilly' && this.filter[key] != null) {
                bodyFormData.append('support_familly', this.filter[key].id);
              } else if (key == 'num' && this.filter[key] != null) {
                bodyFormData.append('num', this.filter[key]);
              } else if (key == 'search' && this.filter[key] != null) {
                bodyFormData.append('search', this.filter[key]);
              } else if (key == 'objet' && this.filter[key] != null) {
                bodyFormData.append('objet', this.filter[key]);
              } else if (Array.isArray(this.filter[key])) {
                if (key == 'filterMasterFiliale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'masters[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterSupport') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'supports[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterFiliale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'clients[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterStatut') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statut[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'filterStatutInstallateur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statue_installateur[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'filiale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'filiale[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'depot') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'depot[' + i + ']',
                      this.filter[key][i]
                    );
                  }
                } else if (key == 'statue') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statue[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'master_filiale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'master_filiale[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'type') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'type[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'etat') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'etat[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'statut_client') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statut_client[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'vendeur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'vendeur_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'categorie') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'categorie_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'acheteur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'acheteur_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'mode_reglement') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'mode_reglement[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'vendeur_id') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'vendeur_id[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'acheteur_id') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'acheteur_id[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'family') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'family[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                }
              } else {
                if (this.filter[key] != null) {
                  bodyFormData.append(key, this.filter[key]);
                }
              }
            });
          }
          const response = await this.exportXls(bodyFormData);
          if (response) {
            this.loading = false;
            this.closeModal();
            this.$emit('end', false);
          } else {
            this.loading = false;
            this.error = 'Une erreur est survenue';
          }
        } else {
          let bodyFormData = new FormData();
          if (this.selected.length != 0) {
            for (let i = 0; i < this.selected.length; i++) {
              bodyFormData.append('ids[' + i + ']', this.selected[i]);
            }
            const response = await this.exportZip(bodyFormData);
            if (response) {
              this.loading = false;
              this.closeModal();
              this.$emit('end', false);
            } else {
              this.loading = false;
              this.error = 'Une erreur est survenue';
            }
          } else {
            this.loading = false;
            this.error = 'Séléctionner au moins un dossier';
          }
        }
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.year = null;
      this.checkAll = false;
      this.typeModal = 'xls';
      this.modelSelected = null;
      this.loading = false;
      this.error = [];
      this.modelSelected = this.computedModel[0]?.id;
      this.modelToUpdate = null;
      this.models?.map(item => {
        if (item.id == this.modelSelected) {
          if (
            item &&
            item.user_name == this.getUserData.name &&
            this.getUserData.role == 'admin'
          ) {
            this.modelToUpdate = { ...item };
            this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
            this.oldModelToUpdate = item;
            let selected = [];
            this.modelToUpdate.columns.forEach(item => {
              if (item.checked == true) {
                selected.push(item);
              }
            });
            if (this.modelToUpdate.columns.length == selected.length)
              this.checkAll = true;
          } else {
            this.modelToUpdate = null;
            this.oldModelToUpdate = null;
            this.oldModalToUpdate = null;
          }
        }
      });
    },
    handleUpdateModel() {
      this.checkAll = false;
      this.models?.map(item => {
        if (item.id == this.modelSelected) {
          if (
            item.user_name == this.getUserData.name ||
            this.getUserData.role == 'admin'
          ) {
            this.modelToUpdate = { ...item };
            this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
            this.oldModelToUpdate = item;
            let selected = [];
            this.modelToUpdate.columns.forEach(item => {
              if (item.checked == true) {
                selected.push(item);
              }
            });
            if (this.modelToUpdate.columns.length == selected.length)
              [(this.checkAll = true)];
          } else {
            this.modelToUpdate = null;
            this.oldModelToUpdate = null;
            this.oldModalToUpdate = null;
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters(['models', 'getUserData']),
    ListType() {
      let list = [];
      if (this.xlsRequired == true) {
        list.push({ value: 'xls', text: 'xls' });
      }
      if (this.zipRequired == true) {
        list.push({ value: 'zip', text: 'zip' });
      }
      return list;
    },
    computedModel() {
      if (this.models.length) {
        return this.models?.map(item => {
          if (item.user_id == 1) {
            return {
              name: item.name,
              id: item.id,
              columns: item.columns,
              value: item.id,
              text: item.name + ' ( Système )'
            };
          } else {
            return {
              name: item.name,
              id: item.id,
              columns: item.columns,
              value: item.id,
              text: item.name + ' (' + item.user_name + ')'
            };
          }
        });
      }
      return [];
    }
  },
  components: {
    IconCloudDownload: () => import('@/assets/img/IconCloudDownload.vue'),
    draggable
  },
  async mounted() {
    await this.getAllModelExports();
    if (this.computedModel && this.computedModel.length) {
      this.modelSelected = this.computedModel[0]?.id;
    }
    this.models?.map(item => {
      if (item.id == this.modelSelected) {
        if (
          item &&
          item.user_name == this.getUserData.name &&
          this.getUserData.role == 'admin'
        ) {
          this.modelToUpdate = { ...item };
          this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
          this.oldModelToUpdate = item;
          let selected = [];
          this.modelToUpdate.columns.forEach(item => {
            if (item.checked == true) {
              selected.push(item);
            }
          });
          if (this.modelToUpdate.columns.length == selected.length)
            [(this.checkAll = true)];
        } else {
          this.modelToUpdate = null;
          this.oldModelToUpdate = null;
          this.oldModalToUpdate = null;
        }
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.select-export-type {
  // margin-bottom: -48px;
  margin-top: 0px;
}
.export-excel-modal {
  // padding: 0px 41px 0px 41px !important;
}
.checked-tous-file {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.draggable-modal {
  width: 100%;
  padding: 0px 0px 0px 11px;
  .row {
    display: flex;
    align-items: baseline;
    height: 70px;
    margin-top: 0px;
    padding: 0px;
    margin-bottom: 4px;
  }
  .theme--light.v-input {
    margin-top: 0px;
    height: 100%;
  }
}
.body-card .draggable-modal .row {
  height: 40px !important;
}
.margin-row-1-export {
  margin-left: -30px;
}
.margin-row-2-export {
  margin-left: -30px;
}
.move-export {
  position: relative;
  top: -1px;
}
</style>

<style lang="scss">
.checked-tous-file {
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
.v-dialog.custom-vuetify-dialog-export-excel.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 0px !important;
  position: absolute !important;
  height: auto;
}
</style>
